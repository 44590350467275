import { gql } from '@apollo/client'
import { ContentStackClient } from '../../../lib/contentstack'
import { Documentation } from '../../../types/contentstack'

export const requestFormattedData = async (): Promise<{
  data?: IFormattedDocumentation[]
  error?: any
}> => {
  try {
    const { data }: IDocumentationPayload = await ContentStackClient().query({
      query: DOCS_MENU_QUERY,
    })

    return {
      data: data.documentation_menu.top_level_parents.map((item) => {
        const content = item.content_referenceConnection?.edges?.[0]?.node

        const children = item.child_referenceConnection?.edges?.map((child) =>
          formatContentNode(child.node)
        )

        return {
          slug: item.slug.substring(1),
          title: item.title,
          description: item.description,
          content: content ? formatContentNode(content) : null,
          children: children || [],
        }
      }),
    }
  } catch (e: any) {
    return { error: e }
  }
}

export const requestDocumentationBody = async (
  uid: string
): Promise<{
  data?: string
  error?: any
}> => {
  try {
    const query = await ContentStackClient().query<{
      documentation: Documentation
    }>({
      query: DOC_BODY_QUERY,
      variables: { uid },
    })

    return { data: query?.data?.documentation?.body! }
  } catch (e: any) {
    return { error: e }
  }
}

export const requestScopesDescription = async (): Promise<{
  data?: IScopes[]
  error?: any
}> => {
  try {
    const { data }: IScopesPayload = await ContentStackClient().query({
      query: SCOPES_QUERY,
    })

    return {
      data: data.all_scopes.items,
    }
  } catch (e: any) {
    return { error: e }
  }
}

const formatContentNode = (node: any): IFormattedContent => {
  return {
    uid: node?.system?.uid,
    title: node.title,
    slug: node.url.substring(1),
    description: node.description,
    body: node.body || '',
    anchorBreakpoints: node.group,
  }
}

export const retrievePaths = (list: IFormattedDocumentation[]): string[][] => {
  let resultPaths: string[][] = []

  list.map((entry) => {
    if (entry.children.length > 0) {
      entry.children.map((child) => {
        resultPaths.push([entry?.slug, child.slug])
      })
    } else {
      resultPaths.push([entry.content?.slug || ''])
    }
  })

  return resultPaths
}

export const findEntryByPath = (
  list: IFormattedDocumentation[],
  path: string[]
): IFormattedContent | undefined => {
  let resultEntry: IFormattedContent | undefined = undefined

  for (let i = 0; i < list.length; i++) {
    const entry = list[i]

    if (checkMatchingPathInSelf(entry, path)) {
      resultEntry = entry.content || undefined
      break
    } else {
      const foundChild = findChildWithMatchingPath(entry, path)

      if (foundChild) {
        resultEntry = foundChild
        break
      }
    }
  }

  return resultEntry
}

export const checkMatchingPathInSelf = (
  entry: IFormattedDocumentation,
  path: string[]
): boolean => {
  return (
    entry.children.length === 0 &&
    path.length === 1 &&
    entry.content?.slug === path[0]
  )
}

export const findChildWithMatchingPath = (
  entry: IFormattedDocumentation,
  path: string[]
): IFormattedContent | undefined => {
  if (entry.children.length === 0) return undefined
  let result: IFormattedContent | undefined = undefined

  for (let i = 0; i < entry.children.length; i++) {
    const child = entry.children[i]

    if (child.slug === path[1] && entry.slug === path[0]) {
      result = child
      break
    }
  }

  return result
}

export const DOCS_MENU_QUERY = gql`
  query DocsMenuQuery {
    documentation_menu(uid: "blt8d401cc475ef07ba") {
      top_level_parents {
        description
        slug
        title
        content_referenceConnection {
          edges {
            node {
              ... on Documentation {
                title
                url
                system {
                  uid
                }
                description
                group {
                  subsection_title
                  url_anchor
                }
              }
            }
          }
        }
        child_referenceConnection {
          edges {
            node {
              ... on Documentation {
                title
                url
                system {
                  uid
                }
                description
                group {
                  subsection_title
                  url_anchor
                }
              }
            }
          }
        }
      }
    }
  }
`

export const DOC_BODY_QUERY = gql`
  query BodyQuery($uid: String!) {
    documentation(uid: $uid) {
      body
    }
  }
`

export const SCOPES_QUERY = gql`
  query ScopesQuery {
    all_scopes(skip: 0, limit: 100) {
      items {
        description
        title
      }
    }
  }
`

interface IScopesPayload {
  data: {
    all_scopes: {
      items: {
        title: string
        description: string
      }[]
    }
  }
}

interface IDocumentationPayload {
  data: {
    documentation_menu: {
      top_level_parents: {
        title: string
        slug: string
        description: string
        child_referenceConnection: IDocumentationContentPayload
        content_referenceConnection: IDocumentationContentPayload
        system: {
          uid: string
        }
      }[]
    }
  }
}

interface IDocumentationContentPayload {
  edges: {
    node: {
      title: string
      url: string
      body: string
      description: string
      system: {
        uid: string
      }
      group: {
        url_anchor: string
        subsection_title: string
      }[]
    }
  }[]
}

export interface IFormattedDocumentation {
  description: string
  slug: string
  title: string
  content: IFormattedContent | null
  children: IFormattedContent[]
}

export interface IScopes {
  description: string
  title: string
}

export interface IFormattedContent {
  uid: string
  title: string
  slug: string
  description: string
  body: string
  anchorBreakpoints: IAnchor[]
}

export interface IAnchor {
  url_anchor: string
  subsection_title: string
}

import {
  ApolloClient,
  ApolloLink,
  concat,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import { Services } from '../../services'

export const ContentStackClient = () => {
  const { api_key, delivery_token, env_name } = Services.contentstack

  const uri = `https://graphql.contentstack.com/stacks/${api_key}?environment=${env_name}`

  const httpLink = new HttpLink({ uri, credentials: 'omit' })

  const authMiddleware = new ApolloLink((operation, forward) => {
    if (!api_key || !delivery_token) {
      // Cancel the request if token is not provided
      return forward(undefined as any)
    }

    operation.setContext({
      headers: {
        version: '1.0.0',
        access_token: delivery_token,
      },
    })

    return forward(operation)
  })

  return new ApolloClient({
    cache: new InMemoryCache().restore({}),
    link: concat(authMiddleware, httpLink),
  })
}

import { getMappedTheme } from '@mapped/rivet/dist/mapped/theme'

const breakpoints = getMappedTheme('light').breakpoints.values

export function mediaScreen(
  min?: TBreakpoint,
  max?: TBreakpoint,
  force?: boolean
) {
  const minWidth = !min ? 0 : breakpoints[min]
  const maxWidth = !max ? 0 : breakpoints[max]

  if (force) {
    return `
      &
    `
  }

  if (minWidth && !maxWidth) {
    return `
      @media (min-width: ${minWidth}px)
    `
  }

  if (!minWidth && maxWidth) {
    return `
      @media (max-width: ${maxWidth}px)
    `
  }

  if (minWidth && maxWidth) {
    return `
      @media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)
    `
  }

  return ``
}

type TBreakpoint = keyof typeof breakpoints

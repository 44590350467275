import { ChevronRight, OpenInNew } from '@mapped/rivet/dist/mui/icons'
import { styled } from '@mapped/rivet/dist/mui/styles'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { UserContext } from '../../contexts/user'
import { IDocsData } from '../../pages/[...id]'
import { Services } from '../../services'
import { OrgSwitcher } from '../auth/orgSwitcher'
import {
  IFormattedContent,
  IFormattedDocumentation,
  checkMatchingPathInSelf,
  findChildWithMatchingPath,
} from './helpers'
import { IAnchorManagement } from './index'

export const LeftPanel: FunctionComponent<IDevMenu> = ({
  data,
  anchorManagement,
  onClose,
}) => {
  const { isSandbox, isOBO } = useContext(UserContext)

  return (
    <Container
      id="left-panel"
      data-testid="docs-explore-menu"
      style={{ paddingBottom: isSandbox || isOBO ? '30px' : 0 }}
    >
      <div className="header" title="Mapped Developer Portal">
        <img
          className="logo"
          src="/img/mapped-logo.svg"
          style={{ height: 120, marginBottom: '-12px' }}
        />

        <span className="title">Developer Portal</span>

        <OrgSwitcher
          fallbackToLoginButton={true}
          onLoginButtonClick={onClose}
        />
      </div>

      {data?.list.map((entry) => {
        const isSelected: boolean =
          checkMatchingPathInSelf(entry, [...data.path]) ||
          findChildWithMatchingPath(entry, [...data.path]) !== undefined

        return (
          <EntryContainer key={entry.slug}>
            <TopLevelLink
              entry={entry}
              isSelected={isSelected}
              onClose={onClose}
            />

            {isSelected && entry.children.length > 0 && (
              <NestedLinks
                entry={entry}
                anchorManagement={anchorManagement!}
                onClose={onClose}
              />
            )}
          </EntryContainer>
        )
      })}

      <a target="_blank" href={Services.api_reference.url}>
        <LinkWithArrow isTopLevel={true} leftPadding={21}>
          <span>API Reference</span>

          <OpenInNew />
        </LinkWithArrow>
      </a>

      <a target="_blank" href={Services.console.url}>
        <LinkWithArrow isTopLevel={true} leftPadding={21}>
          <span>Console</span>

          <OpenInNew />
        </LinkWithArrow>
      </a>
    </Container>
  )
}

const TopLevelLink: FunctionComponent<ITopLevelLink> = ({
  entry,
  isSelected,
  onClose,
}) => {
  const isOpen = entry.children.length > 0 && isSelected
  const isClosed = entry.children.length > 0 && !isSelected

  return (
    <Link
      href={`/${entry.content?.slug || entry.slug}/${
        entry.children[0]?.slug || ''
      }`}
    >
      <LinkWithArrow
        isTopLevel={true}
        leftPadding={21}
        isHighlighted={isSelected}
        onClick={onClose}
      >
        <span>{entry.title}</span>

        {isOpen && <ChevronRight style={{ transform: 'rotate(90deg)' }} />}
        {isClosed && <ChevronRight />}
      </LinkWithArrow>
    </Link>
  )
}

const NestedLinks: FunctionComponent<INestedLinks> = ({
  entry,
  anchorManagement,
  onClose,
}) => {
  return (
    <>
      {entry.children?.map((childEntry) => (
        <Column key={childEntry.slug}>
          <ToggleSubSection
            entry={entry}
            childEntry={childEntry}
            anchorManagement={anchorManagement}
            onClose={onClose}
          />
        </Column>
      ))}
    </>
  )
}

const ToggleSubSection: FunctionComponent<IToggleSubSection> = ({
  entry,
  childEntry,
  anchorManagement,
  onClose,
}) => {
  const router = useRouter()

  // Parse string
  let [lastQueryString] = router.asPath.split('/').slice(-1)
  let [selectedParentSection] = lastQueryString.split('#')

  if (childEntry?.slug !== selectedParentSection) {
    selectedParentSection = router.asPath.split('/').slice(-2).shift()!
  }

  const isSubSectionOpen = selectedParentSection === childEntry?.slug

  return (
    <>
      <Link href={`/${entry.slug}/${childEntry?.slug}`}>
        <LinkWithArrow
          isTopLevel={false}
          isHighlighted={isSubSectionOpen}
          leftPadding={36}
          onClick={onClose}
        >
          <span>{childEntry.title}</span>

          {isSubSectionOpen && !!childEntry.anchorBreakpoints.length && (
            <ChevronRight style={{ transform: 'rotate(90deg)' }} />
          )}
          {!isSubSectionOpen && !!childEntry.anchorBreakpoints.length && (
            <ChevronRight />
          )}
        </LinkWithArrow>
      </Link>

      {isSubSectionOpen && (
        <DisplayAnchors
          anchorManagement={anchorManagement}
          entry={childEntry}
          baseLink={`/${entry.slug}/${childEntry?.slug}`}
          onClose={onClose}
        />
      )}
    </>
  )
}

const DisplayAnchors: FunctionComponent<IAnchors> = ({
  entry,
  anchorManagement,
  baseLink,
  onClose,
}) => {
  const router = useRouter()

  // Parse string on load
  const [lastQueryString] = router.asPath.split('/').slice(-1)
  const [, hashAnchor] = lastQueryString.split('#')

  // Handle anchor state
  const [selectedAnchor] =
    anchorManagement?.selectedAnchor?.anchor?.split('#')?.slice(-1) || ''

  const setSelectedAnchor = anchorManagement?.setSelectedAnchor || (() => {})

  return (
    <Column>
      {entry.anchorBreakpoints.map((anchor, i) => (
        <a
          key={i}
          href={`${baseLink}#${anchor.url_anchor}`}
          onClick={() => {
            onClose?.()
            setSelectedAnchor({
              anchor: anchor.url_anchor,
              parent: entry.slug,
            })

            if (window.innerWidth < 992) {
              setTimeout(() => window.scrollBy(0, -90), 400)
            }
          }}
        >
          <LinkWithArrow
            key={'menu-' + anchor.url_anchor}
            isTopLevel={false}
            isHighlighted={anchor.url_anchor === (selectedAnchor || hashAnchor)}
            leftPadding={57}
          >
            <span>{anchor.subsection_title}</span>
          </LinkWithArrow>
        </a>
      ))}
    </Column>
  )
}

const Container = styled(PerfectScrollbar)`
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${(props) => props.theme.palette.divider};
  width: 300px;
  position: fixed !important;
  z-index: 3;
  background: white;
  top: 0;
  height: 100%;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 10px;
    border-bottom: 1px solid ${(props) => props.theme.palette.divider};

    .title {
      opacity: 0.7;
      font-size: 22px;
      display: block;
      font-weight: bold;
    }

    .sign-in-button,
    .org-switcher {
      width: 100%;
      margin-top: 15px;
      height: 35px;
    }

    .org-switcher {
      &,
      > div {
        height: 35px;
      }

      > div {
        width: calc(100% - 40px);
      }

      .MuiSelect-select {
        padding: 5px 13px;
        padding-right: 35px;
      }

      .MuiSvgIcon-root:not(.MuiSelect-icon):not(.logout) {
        margin-right: 9px !important;
      }

      .MuiSelect-icon {
        margin-right: 0 !important;
      }
    }
  }
`

const EntryContainer = styled.div`
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  height: auto;

  svg {
    fill: ${(props) => props.theme.palette.primary.main};
  }

  &:first-of-type {
    border-top: 1px solid ${(props) => props.theme.palette.divider};
  }
`

const LinkWithArrow = styled.span<{
  isTopLevel: boolean
  isHighlighted?: boolean
  leftPadding: number
}>`
  // All level styling
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-right: 10px;
  color: ${(props) => props.theme.palette.text.a50};

  &:hover {
    color: ${(props) => props.theme.palette.text.primary};
  }

  span {
    padding: 10px 0.5em 8px ${(props) => props.leftPadding}px;
  }

  // Top level styling
  ${({ isTopLevel, theme, leftPadding }) =>
    isTopLevel &&
    `
    color: ${theme.palette.text.primary};

    span {
      padding: 10px 0.5em 10px ${leftPadding}px;
    }
  `}

  // Highlight all selected menu items
  ${({ isHighlighted, theme }) =>
    isHighlighted &&
    `
    /* background-color: ${theme.palette.primary.a10}; */
    color: ${theme.palette.primary.main} !important;
    font-weight: 500;
  `}

  // Re-highlight *top level* menu item
  ${({ isHighlighted, isTopLevel, theme }) =>
    isHighlighted &&
    isTopLevel &&
    `
    color: ${theme.palette.primary.main} !important;
  `}
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

interface IDevMenu {
  data: IDocsData
  anchorManagement?: IAnchorManagement
  onClose?: () => void
}

interface ITopLevelLink {
  entry: IFormattedDocumentation
  isSelected: boolean
  onClose?: () => void
}

interface INestedLinks {
  entry: IFormattedDocumentation
  anchorManagement: IAnchorManagement
  onClose?: () => void
}

interface IToggleSubSection {
  entry: IFormattedDocumentation
  childEntry: IFormattedContent
  anchorManagement: IAnchorManagement
  onClose?: () => void
}

interface IAnchors {
  entry: IFormattedContent
  anchorManagement: IAnchorManagement
  baseLink: string
  onClose?: () => void
}

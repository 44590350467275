import { Tooltip } from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import { CSSProperties, Fragment, FunctionComponent } from 'react'
import { mediaScreen } from '../../utils/mediaScreen'

export const PageTitle: FunctionComponent<IPageTitle> = ({
  id,
  title,
  style,
  withBorder = false,
  onClick,
  children,
}) => {
  function renderTitle() {
    if (typeof title === 'string') {
      return title
    }

    return title.map((t, i) => {
      const isLast = i === title.length - 1

      if (isLast) {
        return <span key={t}>{t}</span>
      }

      return (
        <Fragment key={t}>
          <Tooltip title={`Back to ${t}`}>
            <span onClick={() => onClick?.(t, i)}>{t}</span>
          </Tooltip>

          <b>/</b>
        </Fragment>
      )
    })
  }
  return (
    <Container
      id={id}
      withBorder={withBorder}
      style={style}
      className="page-title"
      data-testid="page-title"
    >
      <h1 className="page-title-text">{renderTitle()}</h1>

      {children}
    </Container>
  )
}

const Container = styled.div<{ withBorder: boolean }>`
  width: 100%;
  margin-bottom: 40px;
  margin-top: -10px;
  display: flex;
  align-items: center;

  ${({ withBorder, theme }) =>
    withBorder &&
    `
    padding-bottom: 31px;
    border-bottom: 1px solid ${theme.palette.divider};
  `}

  &:empty {
    opacity: 0;
  }

  &:not(:empty) {
    opacity: 1;
  }

  .page-title-text {
    flex: 1;
    min-height: 72px;
    font-size: 48px;
    margin: 0;
    font-weight: bold;
    color: ${({ theme }) => theme.palette.text.primary};

    span:not(:last-of-type) {
      opacity: 0.8;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    b {
      margin: 0 15px;
      opacity: 0.2;
      pointer-events: none;

      &::last-of-type {
        display: none;
      }
    }
  }

  ${mediaScreen('xs', 'md')} {
    margin: 0;
    margin-bottom: 0;
    border: none;

    .page-title-text {
      font-size: 32px;
      min-height: auto;

      b {
        margin: 0 7px;
      }
    }
  }
`

interface IPageTitle {
  id?: string
  title: string | string[]
  onClick?: (str: string, idx: number) => void
  style?: CSSProperties
  withBorder?: boolean
}

import { Menu } from '@mapped/rivet/dist/mui/icons'
import { Box, GlobalStyles } from '@mapped/rivet/dist/mui/material'
import { styled } from '@mapped/rivet/dist/mui/styles'
import Router from 'next/router'
import NProgress from 'nprogress'
import { FunctionComponent, useContext, useState } from 'react'
import { UserContext } from '../../contexts/user'
import { IDocsData } from '../../pages/[...id]'
import { mediaScreen } from '../../utils/mediaScreen'
import { OrgSwitcher } from '../auth/orgSwitcher'
import { IAnchorManagement } from '../developer'
import { LeftPanel } from '../developer/left-panel'

NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

export const Layout: FunctionComponent<{
  direction?: 'row' | 'column'
  data: IDocsData
  anchorManagement?: IAnchorManagement
  forceMobile?: boolean
}> = ({
  children,
  data,
  anchorManagement,
  direction = 'column',
  forceMobile,
}) => {
  const { isOBO, isSandbox, organization } = useContext(UserContext)
  const [isPanelOpen, setIsPanelOpen] = useState(false)

  return (
    <>
      <GlobalStyles
        styles={() => {
          return {
            'html, body': {
              scrollBehavior: 'smooth',
              overflow: isPanelOpen ? 'hidden !important' : undefined,
            },
          }
        }}
      />

      <Box
        sx={({ palette }) => ({
          display: 'flex',
          flexDirection: direction,
          width: '100%',
          height: '100%',
          maxWidth: forceMobile ? undefined : 1600,
          position: 'relative',
          margin: '0 auto',

          '@media only screen and (min-width: 1600px)': {
            '#left-panel': {
              borderLeft: forceMobile
                ? undefined
                : `1px solid ${palette.divider}}`,
            },
            '.inner': {
              borderRight: forceMobile
                ? undefined
                : `1px solid ${palette.divider}}`,
            },
          },
        })}
      >
        <Container isPanelOpen={isPanelOpen} forceMobile={forceMobile}>
          <div className="backdrop" onClick={() => setIsPanelOpen(false)} />

          <LeftPanel
            data={data}
            anchorManagement={anchorManagement}
            onClose={() => setIsPanelOpen(false)}
          />

          <MobileTopBar forceMobile={forceMobile}>
            <Menu
              fontSize="large"
              color="secondary"
              sx={{ cursor: 'pointer', ':hover': { opacity: 0.7 } }}
              onClick={() => setIsPanelOpen(true)}
            />

            <div className="logo" title="Mapped Developer Portal">
              <img src="/img/mapped-logo.svg" style={{ height: 48 }} />
              <span className="text">Developer Portal</span>
            </div>

            {!isPanelOpen && (
              <OrgSwitcher
                fallbackToLoginButton={true}
                sx={{ minWidth: 'auto', maxWidth: 'calc(100% - 300px)' }}
              />
            )}
          </MobileTopBar>

          <Inner className="inner" forceMobile={forceMobile}>
            {children}

            {!!isOBO && (
              <ModeBanner
                className="mode-banner"
                style={{ background: '#f2c94c', color: '#000' }}
              >
                You are viewing this portal on behalf of
                <strong style={{ marginLeft: '6px' }}>
                  {organization?.name}
                </strong>
              </ModeBanner>
            )}

            {!!isSandbox && (
              <ModeBanner
                className="mode-banner"
                style={{ background: '#51C0AC', color: 'white' }}
              >
                <strong style={{ marginLeft: '6px' }}>SANDBOX MODE</strong>
              </ModeBanner>
            )}
          </Inner>
        </Container>
      </Box>
    </>
  )
}

const Container = styled.div<{ isPanelOpen?: boolean; forceMobile?: boolean }>`
  display: flex;

  ${(props) => mediaScreen('xs', 'md', props.forceMobile)} {
    #left-panel {
      z-index: 120;
      transition: 300ms ease transform;
      transform: translateX(-300px);
    }

    .backdrop {
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: black;
      z-index: 119;
      opacity: 0;
      pointer-events: none;
      transition: 300ms ease opacity;
    }

    ${(props) =>
      props.isPanelOpen &&
      `
      .backdrop {
        opacity: 0.6;
        pointer-events: auto;
      }

      #left-panel {
        transform: translateX(0);
      }
    `}
  }
`

const Inner = styled.div<{ forceMobile?: boolean }>`
  padding: 40px;
  left: 300px;
  width: calc(100% - 300px);
  display: flex;
  flex-direction: column;
  position: absolute;

  ${(props) => mediaScreen('xs', 'md', props.forceMobile)} {
    left: 0;
    width: 100%;
    padding: 20px;
    padding-top: 100px;
  }
`
const MobileTopBar = styled.div<{ forceMobile?: boolean }>`
  display: none;
  align-items: center;
  background: white;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
  padding: 10px 15px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;

  .logo {
    display: flex;
    align-items: center;
    padding-left: 14px;
    width: 100%;

    img {
      margin-right: 7px;
    }

    .text {
      opacity: 0.7;
      font-size: 18px;
      display: block;
      font-weight: bold;
    }
  }

  .org-switcher {
    display: none;
  }

  ${(props) => mediaScreen('xs', 'md', props.forceMobile)} {
    display: flex;
  }

  @media (min-width: 600px) {
    .org-switcher {
      display: flex;
    }
  }
`

const ModeBanner = styled.div`
  height: 30px;
  left: 0;
  right: 0;
  letter-spacing: 0.5px;
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  font-size: 15px;
  pointer-events: none;
`

import styled from '@emotion/styled'
import { MappedModal, MappedModalProps } from '@mapped/rivet/dist/mapped/modal'
import { Box, TextField, Typography } from '@mapped/rivet/dist/mui/material'
import { LoadingButton } from '@mui/lab'
import { FormEvent, FunctionComponent, useState } from 'react'
import * as auth0 from '../../auth/auth0'
import { useNotifications } from '../../hooks/useNotifications'
import { ECookieName } from '../../types/general'
import { setCookie } from '../../utils/cookies'
import { AuthProviderButtonsMap, EAuthProvider } from './providerButton'

export const AuthenticationModal: FunctionComponent<MappedModalProps> = ({
  onClose,
}) => {
  const [selectedProvider, setSelectedProvider] = useState<EAuthProvider>()

  return (
    <MappedModal
      onClose={onClose}
      open={true}
      style={{ width: 380, overflow: 'visible' }}
    >
      <Container>
        <LoginForm
          selectedProvider={selectedProvider}
          onChangeSelectedProvider={setSelectedProvider}
        />
      </Container>
    </MappedModal>
  )
}

const LoginForm: FunctionComponent<{
  selectedProvider?: EAuthProvider
  onChangeSelectedProvider: (p: EAuthProvider) => void
}> = ({ selectedProvider, onChangeSelectedProvider }) => {
  const notifications = useNotifications()
  const sso = selectedProvider === EAuthProvider.SSO

  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  function onSubmit(e?: FormEvent, provider = EAuthProvider.EMAIL) {
    e?.preventDefault()

    onChangeSelectedProvider(provider)
    setIsLoading(true)

    setCookie(ECookieName.BEFORE_LOGIN_URL, location.href, {
      path: '/',
    })

    switch (provider) {
      case EAuthProvider.GOOGLE:
      case EAuthProvider.MICROSOFT:
      case EAuthProvider.GITHUB:
        return auth0.authorizeWithSocial(provider, {})

      case EAuthProvider.SSO:
        return fetch(`/api/sso/${email.split('@')[1]}`, { method: 'POST' })
          .then((res) => res.json())
          .then(({ connection }) => {
            if (!connection) {
              setIsLoading(false)

              return notifications.push({
                type: 'error',
                message: 'SSO is not enabled for this domain',
              })
            }

            return auth0.authorizeWithSocial(EAuthProvider.SSO, {
              connection,
            })
          })

      case EAuthProvider.EMAIL:
        return auth0
          .authorizeWithCredentials({ email, password })
          .then((res) => {
            setIsLoading(!res.error)
            notifications.push({ type: 'error', message: res.error })
          })
    }
  }

  function renderAuthButton(provider: EAuthProvider) {
    const Button = AuthProviderButtonsMap[provider]

    return (
      <Button
        prefix="Sign in with"
        loading={isLoading && selectedProvider === provider}
        disabled={isLoading && selectedProvider !== provider}
        onClick={() => onSubmit(undefined, provider)}
      />
    )
  }

  return (
    <>
      {!sso && (
        <>
          {renderAuthButton(EAuthProvider.MICROSOFT)}
          {renderAuthButton(EAuthProvider.GITHUB)}

          <SSOButton
            prefix="Sign in with"
            disabled={isLoading}
            onClick={() => onChangeSelectedProvider(EAuthProvider.SSO)}
          />

          <Typography
            paragraph={true}
            sx={(theme) => ({
              fontSize: '16px',
              color: theme.palette.text.a50,
              margin: '16px 0',
              textAlign: 'center',
            })}
          >
            or
          </Typography>
        </>
      )}

      <Box
        sx={{
          form: {
            margin: 0,
            '.MuiInputBase-root': {
              height: '35px',
            },
            '.MuiTextField-root': {
              marginBottom: '11px',
            },
          },
        }}
      >
        <form id="loginForm" onSubmit={(e) => onSubmit(e, selectedProvider)}>
          <TextField
            type="email"
            name="email"
            placeholder="Email"
            size="small"
            fullWidth={true}
            required={true}
            disabled={isLoading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {!sso && (
            <>
              <TextField
                type="password"
                name="password"
                placeholder="Password"
                size="small"
                fullWidth={true}
                required={true}
                disabled={isLoading}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <LoadingButton
                type="submit"
                disabled={isLoading}
                loading={isLoading && selectedProvider === EAuthProvider.EMAIL}
                fullWidth={true}
                size="medium"
              >
                Sign in
              </LoadingButton>
            </>
          )}

          {!!sso && (
            <SSOButton
              type="submit"
              disabled={isLoading}
              loading={isLoading}
              prefix="Sign in with"
              style={{ marginTop: 0 }}
            />
          )}
        </form>
      </Box>
    </>
  )
}

const SSOButton = AuthProviderButtonsMap[EAuthProvider.SSO]

const Container = styled.div`
  padding: 30px;
  text-align: center;
`
